import React from 'react';
import { Link } from 'gatsby';
import Img from "gatsby-image";
import {
    EuiCard,
    EuiFlexGroup,
    EuiFlexItem,
    LEFT_ALIGNMENT,
    EuiDescriptionList,
    EuiHideFor,
    EuiShowFor
  } from '@elastic/eui';


const BrokerCard = (props) => {

    const brokerData = props.data;

    const countries = brokerData.countries ? brokerData.countries.map(elem => {
      return <img style={{ height: 20, width: 20*elem.flag.fluid.aspectRatio, display: "inline", marginBottom: "0.2rem", marginRight: 5 }} src={elem.flag.fluid.src} title={elem.name_ru ? elem.name_ru : elem.name} key={elem.flag.fluid.src} alt={`Логотип ${elem.name_ru}`} />
    }) : `—`

    const max_leverage = brokerData.max_leverage ? `до 1:${brokerData.max_leverage}` : `—`
    const deposit = brokerData.min_deposit ? `от $${brokerData.min_deposit}` : `—`
    const spread = (brokerData.spreads && brokerData.spreads.name_ru) ? brokerData.spreads.name_ru : `—`
    
    const blockData1 = [
      {
        title: 'Страна регистрации:',
        description: <span style={{ maxWidth: 200, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'block' }}>{countries}</span>,
      },
      {
        title: 'Кредитное плечо:',
        description: max_leverage,
      },
      {
        title: 'Депозит:',
        description: deposit,
      },
      {
        title: 'Спред:',
        description: spread,
      },
    ];


    const fundingMethods = brokerData.funding_methods.map(elem => {
      return <img style={{ height: 20, width: 20*elem.logo.fluid.aspectRatio, display: "inline", marginBottom: "0.2rem", marginRight: 5 }} src={elem.logo.fluid.src} title={elem.name_ru ? elem.name_ru : elem.name} key={elem.logo.fluid.src} alt={`Логотип ${elem.name_ru}`} />
    })

    const tradingPlatforms = brokerData.platforms.map(elem => {
      return elem.name_ru ? elem.name_ru : elem.name
    }).join(', ')

    const tradingInstruments = brokerData.instruments.map(elem => {
      return elem.name_ru ? elem.name_ru : elem.name
    }).join(', ')

    const blockData2 = [

      {
        title: 'Торговые платформы:',
        description: tradingPlatforms
      },
      {
        title: 'Торговые инструменты:',
        description: tradingInstruments
      },
      {
        title: 'Платежные системы:',
        description: <span style={{ maxWidth: 200, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'block' }}>{fundingMethods}</span>,
      }
      
    ];


    const cardContent = <>
        <EuiFlexItem>
        <EuiDescriptionList
          listItems={blockData1}
          type="column"
          align="left"
          compressed
        />
        </EuiFlexItem>

        <EuiFlexItem>
        <EuiDescriptionList
          listItems={blockData2}
          type="column"
          align="left"
          compressed
        />
        </EuiFlexItem>
    </>
   

    return (
        
        <Link to={`/${brokerData.slug}/`}>
        <EuiFlexGroup gutterSize="l">
            {brokerData && 
            <EuiFlexItem>
              <EuiCard
                className={`card_shadow`}
                textAlign="left"
                title={`${brokerData.name} (${brokerData.established})`}
                description={
                  <>
                    <Img style={{ width: 100, float: LEFT_ALIGNMENT }}  fluid={brokerData.logo.fluid} alt={`Логотип брокера ${brokerData.name}`} />

                    <EuiHideFor sizes={['xs', 's', 'm', 'l']}>
                      <EuiFlexGroup>
                        {cardContent}
                      </EuiFlexGroup>
                    </EuiHideFor>

                    <EuiShowFor sizes={['xs', 's', 'm', 'l']}>
                      <EuiFlexGroup style={{ display: 'block' }}>
                        {cardContent}
                      </EuiFlexGroup>
                    </EuiShowFor>
                  </>
              }
              />
              
            </EuiFlexItem>
            } 
      </EuiFlexGroup>
      </Link>  
    )
}

export default BrokerCard;